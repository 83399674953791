import React from 'react';
import { usePlayers } from '../../stores/usePlayers';
import { PartyMember } from './PartyMember';

interface PartyBuilderProps {}

export const PartyBuilder: React.FC<PartyBuilderProps> = (props) => {
  const { addPlayer, changeWeapon, toggleWeaponSkill } = usePlayers();
  const players = usePlayers((s) => s.players.toList());

  return (
    <div className="party-builder">
      <div className="add-player">
        <div onClick={addPlayer} className="add-member party-member">
          Click here to add a Party Member
        </div>
      </div>
      {players.map((player) => (
        <PartyMember
          key={player.name}
          player={player}
          setWeapon={(w) => changeWeapon(player.id, w)}
          toggleWeaponSkill={toggleWeaponSkill}
        />
      ))}
    </div>
  );
};
