import { Point, resolve } from '@xi/types/maps/Point';
import json from './treasures.json';

const chests: Record<string, Point[]> = {};
const coffers: Record<string, Point[]> = {};

for (const point of json['chests']) {
  const resolved = resolve(point);
  if (resolved.map !== 'no_map') {
    chests[resolved.map] ??= [];
    chests[resolved.map].push(resolved);
  }
}

for (const point of json['coffers']) {
  const resolved = resolve(point);
  if (resolved.map !== 'no_map') {
    coffers[resolved.map] ??= [];
    coffers[resolved.map].push(resolved);
  }
}

export function getChestsFor(map: string): Point[] {
  return chests[map] ?? [];
}

export function getCoffersFor(map: string): Point[] {
  return coffers[map] ?? [];
}
