import { FormControl, InputLabel } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import humanizeString from 'humanize-string';
import startCase from 'lodash/startCase';
import React from 'react';
import { Weapon } from '../../types/skillchain/Weapon';

interface WeaponSelectProps {
  setWeapon: (w: Weapon) => void;
  weapon: Weapon;
}

export const WeaponSelect: React.FC<WeaponSelectProps> = ({
  weapon,
  setWeapon,
}) => {
  return (
    <FormControl style={{ marginTop: 0 }} fullWidth margin="normal">
      <InputLabel>Weapon Type</InputLabel>
      <Select
        className="weapon-select"
        label="Weapon Type"
        size="small"
        value={weapon}
        onChange={(e) => {
          setWeapon(e.target.value as Weapon);
        }}
      >
        {Object.values(Weapon).map((w) => {
          const weaponType = startCase(humanizeString(w));
          return (
            <MenuItem key={w} value={w}>
              {weaponType}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
