import { Image } from 'image-js';
import { useEffect, useState } from 'react';
import useSWR from 'swr';

const placeholderImage = new Image(512, 512);

function useMakeImage(blob: Blob | undefined): Image {
  const [image, setImage] = useState<Image>(placeholderImage);
  useEffect(() => {
    if (blob) {
      const asyncer = async () => {
        const buffer = await blob.arrayBuffer();
        const image = await Image.load(buffer);
        setImage(image);
      };
      void asyncer();
    }
  }, [blob]);
  return image;
}

export function useFetchImage(url: string | null): {
  blob: Blob | undefined;
  image: Image;
} {
  const { data: blob } = useSWR(url, (...args) =>
    fetch(...args).then((resp) => resp.blob())
  );
  const image = useMakeImage(blob);
  return { blob, image };
}
