import { List, Map, Set } from 'immutable';
import take from 'lodash/take';
import { create } from 'zustand';
import { Player, PlayerId, PlayerName } from '../types/skillchain/Player';
import { Weapon } from '../types/skillchain/Weapon';
import {
  WeaponSkill,
  WeaponToWeaponSkills,
} from '../types/skillchain/WeaponSkill';

const defaultParty = Map<PlayerId, Player>(List([]));

interface PlayersState {
  players: Map<PlayerId, Player>;
  addPlayer: () => void;
  removePlayer: (id: PlayerId) => void;
  changeWeapon: (id: PlayerId, weapon: Weapon) => void;
  toggleWeaponSkill: (id: PlayerId, weaponSkill: WeaponSkill) => void;
  setName: (id: PlayerId, name: PlayerName) => void;
}

export const usePlayers = create<PlayersState>()((set) => ({
  players: defaultParty,
  toggleWeaponSkill: (id, weaponSkill) =>
    set((state) => ({
      players: state.players.update(id, (player) =>
        player!.update('activeWeaponSkills', (skills) =>
          skills.has(weaponSkill)
            ? skills.delete(weaponSkill)
            : skills.add(weaponSkill)
        )
      ),
    })),
  addPlayer: () =>
    set((state) => {
      const player = new Player();
      return { players: state.players.set(player.id, player) };
    }),
  removePlayer: (id) =>
    set((state) => {
      return { players: state.players.delete(id) };
    }),
  changeWeapon: (id, weapon) =>
    set((state) => ({
      players: state.players.update(id, (p) => {
        const skills = WeaponToWeaponSkills[weapon]!;
        return p!
          .set('weapon', weapon)
          .set('activeWeaponSkills', Set(take(skills as any, 3)));
      }),
    })),
  setName: (id: PlayerId, name: PlayerName) =>
    set((state) => ({
      players: state.players.update(id, (p) => p!.set('name', name)),
    })),
}));
