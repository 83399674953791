// area and id we take from AirSkyBoat code
// https://github.com/AirSkyBoat/AirSkyBoat/blob/staging/scripts/globals/quests.lua
import { resolve } from '@xi/types/maps/Point';
import { Location, Quest, QuestStep } from '@xi/types/maps/Quest';

/* eslint-disable import/order */
// area=1
import bastokQuestsJson from './quests/bastok.json';
// area=0
import sanDoriaQuestsJson from './quests/san_doria.json';
// area=2
import windurstQuestsJson from './quests/windurst.json';
// area=3
import jeunoQuestsJson from './quests/jeuno.json';
// area=4
import otherAreaQuestsJson from './quests/other.json';
// area=5
import outlandsQuestsJson from './quests/outlands.json';

// missions we prefix with m
import windurstMissionsJson from './quests/windurst_missions.json';
import zilartMissionsJson from './quests/zilart_missions.json';
import promathiaMissionsJson from './quests/promathia_missions.json';

import { getChildren } from './factors';
import { getSpawnsFor } from './spawns';
import { getChestsFor, getCoffersFor } from './treasures';
/* eslint-enable import/order */

type RawPoint = {
  map: string;
  label?: string;
  x: number;
  y: number;
  z: number;
};
// Matches definition in ASB
type TriggerArea = {
  map: string;
  label?: string;
  x1: number;
  y1: number;
  z1: number;
  x2: number;
  y2: number;
  z2: number;
};
type Type = { map: string; type: string; arg?: string };

type RawLocation = RawPoint | TriggerArea | Type;

function isLocation(point: RawLocation): point is RawPoint {
  return (point as RawPoint).x !== undefined;
}

function isTriggerArea(point: RawLocation): point is TriggerArea {
  return (point as TriggerArea).x1 !== undefined;
}

function isQuestCategoryJson(step: QuestStepJson): step is QuestCategoryJson {
  return (step as QuestCategoryJson).steps !== undefined;
}

function buildQuestSteps(
  steps: QuestStepJson[],
  prefix: string = ''
): QuestStep[] {
  return steps.map((step, idx) => {
    if (isQuestCategoryJson(step)) {
      return {
        note: step.note,
        steps: buildQuestSteps(step.steps, `${prefix}${idx}.`),
      };
    }
    return {
      id: `${prefix}${idx}`,
      location: convertLocation(step.location),
      note: step.note,
    };
  });
}

function buildQuests(area: number | string, quests: QuestJson[]): Quest[] {
  return quests.map(({ steps, id, ...quest }) => ({
    ...quest,
    area,
    id: `${area}.${id}`,
    steps: buildQuestSteps(steps),
  }));
}

// This type isn't used, but this is the type of the json files.
interface QuestPointJson {
  location: RawLocation | RawLocation[];
  note: string;
}

interface QuestCategoryJson {
  note: string;
  steps: QuestStepJson[];
}

type QuestStepJson = QuestPointJson | QuestCategoryJson;

interface QuestJson {
  id: number;
  name: string;
  // Prereqs needed to start the quest
  prereqs: string;
  // requirements of what's needed to finish the test
  requirements: string;
  reward: string;
  steps: QuestStepJson[];
}

function convertLocation(location: RawLocation | RawLocation[]): Location[] {
  const points = Array.isArray(location) ? location : [location];
  return points
    .map((point) => {
      if (isLocation(point)) {
        return resolve(point);
      } else if (isTriggerArea(point)) {
        const point1 = resolve({
          map: point.map,
          x: point.x1,
          y: point.y1,
          z: point.z1,
        });
        // If second point is all 0s, preserve it
        const point2 =
          point.x2 === 0 && point.y2 === 0 && point.x2 === 0
            ? undefined
            : resolve({
                map: point.map,
                x: point.x2,
                y: point.y2,
                z: point.z2,
              });
        if (point2 && point2.map !== point1.map) {
          console.warn(
            `Two different maps on TriggerArea: ${JSON.stringify(point)}`
          );
        }
        return {
          map: point1.map,
          label: point.label,
          first: point1,
          second: point2,
        };
      } else if (point.type === 'chest') {
        const maps = [
          point.map,
          ...getChildren(point.map).map((factors) => factors.key),
        ];
        return maps.map((map) => getChestsFor(map)).flat();
      } else if (point.type === 'coffer') {
        const maps = [
          point.map,
          ...getChildren(point.map).map((factors) => factors.key),
        ];
        return maps.map((map) => getCoffersFor(map)).flat();
      } else if (point.type === 'mob' && point.arg !== undefined) {
        const maps = [
          point.map,
          ...getChildren(point.map).map((factors) => factors.key),
        ];
        return maps
          .map((map) =>
            getSpawnsFor(map, point.arg).map((spawn) => spawn.point)
          )
          .flat();
      } else {
        return [];
      }
    })
    .flat();
}

export const quests: Quest[] = [
  ...buildQuests(0, sanDoriaQuestsJson as QuestJson[]),
  ...buildQuests(1, bastokQuestsJson as QuestJson[]),
  ...buildQuests(2, windurstQuestsJson as QuestJson[]),
  ...buildQuests(3, jeunoQuestsJson as QuestJson[]),
  ...buildQuests(4, otherAreaQuestsJson as QuestJson[]),
  ...buildQuests(5, outlandsQuestsJson as QuestJson[]),
  ...buildQuests('m2', windurstMissionsJson as QuestJson[]),
  ...buildQuests('m3', zilartMissionsJson as QuestJson[]),
  ...buildQuests('m6', promathiaMissionsJson as QuestJson[]),
];
