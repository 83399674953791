export enum Environment {
  Production,
  Development,
}

const environment =
  process.env.NODE_ENV === 'development'
    ? Environment.Development
    : Environment.Production;

export function getEnvironment(): Environment {
  return environment;
}
