import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import humanizeString from 'humanize-string';
import { Set } from 'immutable';
import startCase from 'lodash/startCase';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { getSkillchains2 } from '../../SkillchainService';
import { usePlayers } from '../../stores/usePlayers';
import { Attribute, Attributes } from '../../types/skillchain/Attribute';
import {
  SkillchainStep,
  SkillchainSteps,
} from '../../types/skillchain/SkillchainStep';
import { WeaponSkill } from '../../types/skillchain/WeaponSkill';
import { ArrowRight } from './ArrowRight';
import { AttributeIcon } from './AttributeIcon';

const NOT_ENOUGH_MEMBERS = `Please add at least two players on the left to form skillchains with.`;
const NO_SKILLCHAINS = `Could not find any skillchains with the selections provided.`;

interface SkillchainDisplayProps {}

export const SkillchainDisplay: React.FC<SkillchainDisplayProps> = () => {
  const [locked, setLocked] = useState<Set<SkillchainSteps>>(Set());
  const toggleLocked = useCallback(
    (steps: SkillchainSteps) =>
      setLocked((curLocked) => {
        if (curLocked.has(steps)) {
          return curLocked.delete(steps);
        }
        return curLocked.add(steps);
      }),
    [setLocked]
  );
  const players = usePlayers((s) => s.players);
  const filteredPlayers = useMemo(
    () =>
      players.filter((p) => {
        for (const lockedSteps of locked) {
          if (lockedSteps.some((step) => step.player === p.id)) {
            return false;
          }
        }
        return true;
      }),
    [players, locked]
  );
  const [attributes, setAttributes] = useState(Set(Attributes));
  const toggleAttribute = useCallback(
    (attribute: Attribute) => {
      setAttributes((attrs) => {
        if (attrs.has(attribute)) {
          return attrs.delete(attribute);
        }
        return attrs.add(attribute);
      });
    },
    [setAttributes]
  );

  const skillchains = getSkillchains2(filteredPlayers.toList());

  // Whenever players change, verify if locked is still valid
  useEffect(() => {
    for (const lockedSteps of locked) {
      if (lockedSteps.some((step) => !players.has(step.player))) {
        toggleLocked(lockedSteps);
      }
    }
  }, [locked, players, toggleLocked]);

  const displaySkillchains = useMemo(
    () =>
      locked
        .toList()
        .concat(skillchains)
        .filter((sc) => {
          return attributes.has(sc.getIn([-1, 'result']) as Attribute);
        }),
    [attributes, locked, skillchains]
  );

  const error =
    players.size < 2
      ? NOT_ENOUGH_MEMBERS
      : displaySkillchains.isEmpty()
      ? NO_SKILLCHAINS
      : undefined;

  return (
    <div className="skillchain-display">
      <Typography className="skillchain-header">Attribute Filters</Typography>
      <Typography className="skillchain-set">
        <Grid container spacing={1} width={832}>
          {Attributes.map((attribute) => (
            <Grid item xs={4}>
              <AttributeToggle
                attribute={attribute}
                isSelected={attributes.has(attribute)}
                toggleAttribute={toggleAttribute}
              />
            </Grid>
          ))}
        </Grid>
      </Typography>
      <Divider sx={{ my: 2 }} />

      <Typography className="skillchain-header">
        Possible Skillchains:
      </Typography>
      {error && <div className="error-state">{error}</div>}

      <Typography className="skillchain-set">
        <Grid container rowSpacing={1} width={832}>
          {displaySkillchains.map((chain, i) => (
            <SkillchainEntry
              chain={chain}
              locked={locked}
              toggle={toggleLocked}
            />
          ))}
        </Grid>
      </Typography>
    </div>
  );
};

interface SkillchainEntryProps {
  chain: SkillchainSteps;
  locked: Set<SkillchainSteps>;
  toggle: (steps: SkillchainSteps) => void;
}
function SkillchainEntry({ chain, locked, toggle }: SkillchainEntryProps) {
  const { skill: opener, attr: openerAttr } = chain.get(0) as SkillchainStep;
  const { result } = chain.get(-1) as SkillchainStep;
  const handleClick = useCallback(() => toggle(chain), [chain, toggle]);
  const isLocked = locked.has(chain);
  return (
    <>
      <Grid
        borderBottom="1px groove var(--dark-gray)"
        item
        sx={{ opacity: isLocked ? 0.6 : 1, pb: 1 }}
        xs={3}
      >
        {isLocked ? (
          <LockIcon onClick={handleClick} />
        ) : (
          <LockOpenIcon onClick={handleClick} />
        )}
        <DisplayWeaponSkill weaponSkill={opener} />
        <AttributeIcon attribute={openerAttr} />
      </Grid>
      <Grid
        borderBottom="1px groove var(--dark-gray)"
        item
        sx={{ opacity: isLocked ? 0.6 : 1, pb: 1 }}
        xs={6}
      >
        <Stack>
          {chain.slice(1).map((step, i) => (
            <SkillchainCloser isLast={i === chain.size - 2} step={step} />
          ))}
        </Stack>
      </Grid>
      <Grid
        borderBottom="1px groove var(--dark-gray)"
        item
        sx={{ opacity: isLocked ? 0.6 : 1, pb: 1 }}
        xs={3}
      >
        <ArrowRight />
        <DisplayAttribute attribute={result} />
        <AttributeIcon attribute={result} />
      </Grid>
    </>
  );
}

interface SkillchainCloserProps {
  isLast: boolean;
  step: SkillchainStep;
}

function SkillchainCloser({ isLast, step }: SkillchainCloserProps) {
  return (
    <div>
      <ArrowRight />
      <DisplayWeaponSkill weaponSkill={step.get('skill') as any} />
      <AttributeIcon attribute={step.get('attr')} />
      {!isLast && (
        <Box component="span" sx={{ opacity: 0.5 }}>
          (
          <DisplayAttribute attribute={step.get('result')} />
          <AttributeIcon attribute={step.get('result')} />)
        </Box>
      )}
    </div>
  );
}

interface DisplayWeaponSkillProps {
  weaponSkill: WeaponSkill;
}
function DisplayWeaponSkill({ weaponSkill }: DisplayWeaponSkillProps) {
  return (
    <span className="ws-display">{startCase(humanizeString(weaponSkill))}</span>
  );
}
interface DisplayAttributeProps {
  attribute: Attribute;
}
function DisplayAttribute({ attribute }: DisplayAttributeProps) {
  return (
    <span className="sc-display">{startCase(humanizeString(attribute))}</span>
  );
}

interface AttributeToggleProps {
  attribute: Attribute;
  isSelected: boolean;
  toggleAttribute: (attribute: Attribute) => void;
}
function AttributeToggle({
  attribute,
  isSelected,
  toggleAttribute,
}: AttributeToggleProps) {
  const handleClick = useCallback(
    () => toggleAttribute(attribute),
    [attribute, toggleAttribute]
  );
  return (
    <Box onClick={handleClick} sx={{ opacity: isSelected ? 1 : 0.6 }}>
      <DisplayAttribute attribute={attribute} />
      <AttributeIcon attribute={attribute} />
    </Box>
  );
}
