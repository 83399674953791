import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Stack from '@mui/material/Stack';
import React, { useCallback } from 'react';
import { useMapSettings } from '@xi/stores/useMapSettings';

function useBooleanEventCallback(
  cb: (value: boolean) => void
): (evt: React.ChangeEvent<HTMLInputElement>) => void {
  return useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      cb(evt.target.checked);
    },
    [cb]
  );
}

export function Settings(): React.ReactElement<{}, typeof Settings> {
  const setShowChests = useBooleanEventCallback(
    useMapSettings((s) => s.setShowChests)
  );
  const setShowCoffers = useBooleanEventCallback(
    useMapSettings((s) => s.setShowCoffers)
  );
  const setUseVanillaMaps = useBooleanEventCallback(
    useMapSettings((s) => s.setUseVanillaMaps)
  );
  const settings = useMapSettings((s) => s.settings);

  return (
    <Stack spacing={0} sx={{ m: 1 }} direction="column" width="100%">
      <FormControlLabel
        control={
          <Checkbox
            checked={settings.get('showChests')}
            onChange={setShowChests}
          />
        }
        label="Show Chests"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={settings.get('showCoffers')}
            onChange={setShowCoffers}
          />
        }
        label="Show Coffers"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={settings.get('useVanillaMaps')}
            onChange={setUseVanillaMaps}
          />
        }
        label="Use Vanilla Maps"
      />
    </Stack>
  );
}
