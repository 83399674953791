import { Map, List, Set } from 'immutable';

export enum Attribute {
  TRANSFIXION = 'TRANSFIXION',
  LIQUEFACTION = 'LIQUEFACTION',
  IMPACTION = 'IMPACTION',
  DETONATION = 'DETONATION',
  COMPRESSION = 'COMPRESSION',
  SCISSION = 'SCISSION',
  REVERBERATION = 'REVERBERATION',
  INDURATION = 'INDURATION',
  FUSION = 'FUSION',
  FRAGMENTATION = 'FRAGMENTATION',
  GRAVITATION = 'GRAVITATION',
  DISTORTION = 'DISTORTION',
  LIGHT = 'LIGHT',
  DARKNESS = 'DARKNESS',
}

export enum Element {
  LIGHT = 'LIGHT',
  FIRE = 'FIRE',
  LIGHTNING = 'LIGHTNING',
  WIND = 'WIND',
  DARK = 'DARK',
  EARTH = 'EARTH',
  WATER = 'WATER',
  ICE = 'ICE',
}

const A = Attribute;
// Key is the pair of skillchains needed, and value is the resulting Attribute
// prettier-ignore
export const AttributeCombinations = Map<List<Attribute>, Attribute>(
    List([
      // Order matters here.
      // LEVEL 1
      //---    Opener           Closer             Result
      [List([A.COMPRESSION,   A.TRANSFIXION]),   A.TRANSFIXION],
      [List([A.IMPACTION,     A.LIQUEFACTION]),  A.LIQUEFACTION],
      [List([A.SCISSION,      A.LIQUEFACTION]),  A.LIQUEFACTION],
      [List([A.REVERBERATION, A.IMPACTION]),     A.IMPACTION],
      [List([A.INDURATION,    A.IMPACTION]),     A.IMPACTION],
      [List([A.IMPACTION,     A.DETONATION]),    A.DETONATION],
      [List([A.COMPRESSION,   A.DETONATION]),    A.DETONATION],
      [List([A.SCISSION,      A.DETONATION]),    A.DETONATION],
      [List([A.TRANSFIXION,   A.COMPRESSION]),   A.COMPRESSION],
      [List([A.INDURATION,    A.COMPRESSION]),   A.COMPRESSION],
      [List([A.LIQUEFACTION,  A.SCISSION]),      A.SCISSION],
      [List([A.DETONATION,    A.SCISSION]),      A.SCISSION],
      [List([A.TRANSFIXION,   A.REVERBERATION]), A.REVERBERATION],
      [List([A.SCISSION,      A.REVERBERATION]), A.REVERBERATION],
      [List([A.REVERBERATION, A.INDURATION]),    A.INDURATION],
      // LEVEL 2
      [List([A.LIQUEFACTION,  A.IMPACTION]),     A.FUSION],
      [List([A.DISTORTION,    A.FUSION]),        A.FUSION],
      [List([A.INDURATION,    A.REVERBERATION]), A.FRAGMENTATION],
      [List([A.GRAVITATION,   A.FRAGMENTATION]), A.FRAGMENTATION],
      [List([A.DETONATION,    A.COMPRESSION]),   A.GRAVITATION],
      [List([A.FUSION,        A.GRAVITATION]),   A.GRAVITATION],
      [List([A.TRANSFIXION,   A.SCISSION]),      A.DISTORTION],
      [List([A.FRAGMENTATION, A.DISTORTION]),    A.DISTORTION],
      // LEVEL 3
      [List([A.FUSION,        A.FRAGMENTATION]), A.LIGHT],
      [List([A.LIGHT,         A.LIGHT]),         A.LIGHT],
      [List([A.GRAVITATION,   A.DISTORTION]),    A.DARKNESS],
      [List([A.DARKNESS,      A.DARKNESS]),      A.DARKNESS],
    ])
);

const E = Element;
// prettier-ignore
export const AttributeToElement = Map<Attribute, Set<Element>>(
    List([
      [A.TRANSFIXION,   Set([E.LIGHT])],
      [A.LIQUEFACTION,  Set([E.FIRE])],
      [A.IMPACTION,     Set([E.LIGHTNING])],
      [A.DETONATION,    Set([E.WIND])],
      [A.COMPRESSION,   Set([E.DARK])],
      [A.SCISSION,      Set([E.EARTH])],
      [A.REVERBERATION, Set([E.WATER])],
      [A.INDURATION,    Set([E.ICE])],
      [A.FUSION,        Set([E.FIRE, E.LIGHT])],
      [A.FRAGMENTATION, Set([E.WIND, E.LIGHTNING])],
      [A.GRAVITATION,   Set([E.EARTH, E.DARK])],
      [A.DISTORTION,    Set([E.ICE, E.WATER])],
      [A.LIGHT,         Set([E.LIGHT, E.FIRE, E.WIND, E.LIGHTNING])],
      [A.DARKNESS,      Set([E.DARK, E.EARTH, E.ICE, E.WATER])],
    ])
);

export const Attributes = List([
  A.TRANSFIXION,
  A.LIQUEFACTION,
  A.IMPACTION,
  A.DETONATION,
  A.COMPRESSION,
  A.SCISSION,
  A.REVERBERATION,
  A.INDURATION,
  A.FUSION,
  A.FRAGMENTATION,
  A.GRAVITATION,
  A.DISTORTION,
  A.LIGHT,
  A.DARKNESS,
]);
