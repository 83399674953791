import { DirectedGraph } from '@datastructures-js/graph';
import {
  Attribute,
  AttributeCombinations,
} from '../types/skillchain/Attribute';

export const AttributeGraph = new DirectedGraph<Attribute, Attribute>();

for (const skillchain of Object.values(Attribute)) {
  AttributeGraph.addVertex(skillchain, skillchain);
}

for (const [[opener, closer]] of AttributeCombinations) {
  AttributeGraph.addEdge(opener, closer);
}
