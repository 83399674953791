import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import React from 'react';
import { NavLink } from 'react-router-dom';

interface NavBarItemProps {
  label: string;
  to: string;
}

function NavBarItem({
  label,
  to,
}: NavBarItemProps): React.ReactElement<NavBarItemProps, typeof NavBarItem> {
  return (
    <NavLink to={to}>
      {({ isActive }) => (
        <Button
          size="large"
          sx={{ bgcolor: isActive ? 'var(--dark-gray)' : '' }}
        >
          {label}
        </Button>
      )}
    </NavLink>
  );
}

export function NavBar(): React.ReactElement<{}, typeof NavBar> {
  return (
    <AppBar position="sticky">
      <Toolbar>
        <NavBarItem label="Maps" to="/maps" />
        <NavBarItem label="Skill Chain" to="/skillchain" />
      </Toolbar>
    </AppBar>
  );
}
