import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { quests } from '@xi/data/maps/quests';
import {
  isQuestCategory,
  isQuestPoint,
  Quest,
  QuestPoint,
  QuestStep,
} from '@xi/types/maps/Quest';

function findQuestStep(steps: QuestStep[], id: string): QuestPoint | undefined {
  const parts = id.split('.').map(Number);
  if (parts.some(isNaN)) {
    return undefined;
  }

  let next = parts.shift() as number;
  let step = steps[next];
  while (parts.length !== 0) {
    if (!step || !isQuestCategory(step)) {
      return undefined;
    }
    next = parts.shift() as number;
    step = step.steps[next];
  }
  // Ensure we return a QuestPoint
  return isQuestPoint(step) ? step : undefined;
}

/**
 * Returns [Quest, QuestStep, number (QuestStep idx)]
 */
export function useQuest(): [
  Quest | undefined,
  QuestPoint | undefined,
  string | undefined
] {
  const [searchParams] = useSearchParams();
  const questParam = searchParams.get('q') ?? '';
  const stepParam = searchParams.get('qs') ?? '';

  const quest = useMemo(() => {
    const quest = quests.find((q) => q.id === questParam);
    if (!quest) {
      return undefined;
    }
    return quest;
  }, [questParam]);

  const [step, stepNum] = useMemo(() => {
    if (!quest || !stepParam) {
      return [undefined, undefined];
    }
    const step = findQuestStep(quest.steps, stepParam);
    if (!step) {
      return [undefined, undefined];
    }
    return [step, stepParam];
  }, [quest, stepParam]);

  return [quest, step, stepNum];
}
