import { Image } from 'image-js';
import L, { LatLngBoundsExpression } from 'leaflet';
import React from 'react';
import { Rectangle } from 'react-leaflet';
import { useMapDescriptor } from '@xi/hooks/useMapDescriptor';
import { useMapSettings } from '@xi/stores/useMapSettings';
import { Boundary } from '@xi/types/maps/Factors';
import { resolve } from '@xi/types/maps/Point';
import { useMap } from './MapContext';

export function Boundaries(): React.ReactElement<{}, typeof Boundaries> {
  const showBoundaries = useMapSettings((s) =>
    s.settings.get('showBoundaries')
  );
  const descriptor = useMapDescriptor();
  const { image } = useMap();

  if (!showBoundaries) {
    return <></>;
  }

  return (
    <>
      {descriptor.getBoundaries().map((boundary, idx) => (
        <Rectangle
          key={idx}
          bounds={getBounds(descriptor.name, image, boundary)}
          interactive={false}
        />
      ))}
    </>
  );
}

function getBounds(
  map: string,
  image: Image,
  boundary: Boundary
): LatLngBoundsExpression {
  const first = resolve({
    map,
    x: boundary.minX,
    y: boundary.minY,
    z: boundary.minZ,
  });
  const second = resolve({
    map,
    x: boundary.maxX,
    y: boundary.maxY,
    z: boundary.maxZ,
  });
  return L.latLngBounds([first.toLatLng(image), second.toLatLng(image)]);
}
