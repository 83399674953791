import { useCallback } from 'react';
import {
  To,
  useNavigate as useNavigateReact,
  useSearchParams,
} from 'react-router-dom';

type Params = Record<string, any>;

// Some params cause other params to be removed
// Defined this here for consistency
// We want to check exclusives first because they may still be explicitly set, this is mainly just
// url cleanup on events.
const exclusiveParams: Record<string, string[]> = {
  // coords don't make sense on the new map
  map: ['from', 'mob', 's', 'x', 'y', 'z'],
  q: ['qs', 's', 'x', 'y', 'z'],
  qs: ['s', 'x', 'y', 'z'],
  // setting x or y (via click or other action) will never need s or z
  x: ['s', 'z'],
  y: ['s', 'z'],
};

export function useNavigate(): (params: Params) => void {
  const navigate = useNavigateReact();
  const [searchParams] = useSearchParams();
  return useCallback(
    (params: Params) => {
      navigate(createUrl(params, searchParams));
    },
    [navigate, searchParams]
  );
}

export function useCreateUrl(): (params: Params) => To {
  const [searchParams] = useSearchParams();
  return useCallback(
    (params: Params) => createUrl(params, searchParams),
    [searchParams]
  );
}

function createUrl(params: Params, searchParams: URLSearchParams): To {
  const dst: To = {};

  // Remove exclusives already in the params
  for (const key in params) {
    const exclusives = exclusiveParams[key] ?? [];
    for (const exclusive of exclusives) {
      searchParams.delete(exclusive);
    }
  }

  if (params['map']) {
    dst.pathname = `/maps/${params['map']}`;
    delete params['map'];
  }

  for (const key in params) {
    const value = params[key];
    if (value === undefined) {
      searchParams.delete(key);
    } else if (Array.isArray(value)) {
      searchParams.delete(key);
      for (const v of value) {
        searchParams.append(key, v);
      }
    } else {
      searchParams.set(key, value);
    }
  }

  dst.search = searchParams.toString();
  return dst;
}
