import { Image } from 'image-js';
import { Map as LeafletMap } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import React, { useContext, useMemo } from 'react';
import { useMap as useLeafletMap } from 'react-leaflet';

interface MapContextData {
  blob: Blob;
  image: Image;
  map: LeafletMap;
}

const MapContext = React.createContext<MapContextData | undefined>(undefined);

export function useMap(): MapContextData {
  const mapData = useContext(MapContext);
  if (!mapData) {
    throw new Error('useMap used outside of MapContext');
  }
  return mapData;
}

interface MapContextProviderProps {
  blob: Blob | undefined;
  children: React.ReactNode;
  image: Image;
}

export function MapContextProvider({
  children,
  ...props
}: MapContextProviderProps): React.ReactElement<
  MapContextProviderProps,
  typeof MapContextProvider
> {
  const map = useLeafletMap();
  const result = useMemo(() => ({ ...props, map }), [map, props]);

  // For convenience we don't type it this way, but blob can be undefined before the map is loaded
  if (!result.blob) {
    return <></>;
  }

  return (
    <MapContext.Provider value={result as MapContextData}>
      {children}
    </MapContext.Provider>
  );
}
