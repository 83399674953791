import { List, Set } from 'immutable';
import { AttributeGraph } from './const/AttributeGraph';
import { Attribute, AttributeCombinations } from './types/skillchain/Attribute';
import { Player } from './types/skillchain/Player';
import {
  SkillchainStep,
  SkillchainSteps,
} from './types/skillchain/SkillchainStep';
import { WeaponSkill } from './types/skillchain/WeaponSkill';
import {
  AttributeToSkills,
  WeaponSkillAttributes,
} from './types/skillchain/WeaponSkillAttributes';

export function getSkillchains2(players: List<Player>): List<SkillchainSteps> {
  let skillchains = List<SkillchainSteps>();

  for (const player of players) {
    const playerSkillchains = player.activeWeaponSkills
      .map((openerSkill) => {
        const openerAttrs = WeaponSkillAttributes.get(
          openerSkill,
          List<Attribute>()
        );
        return openerAttrs.map((openerAttr) =>
          List.of(
            new SkillchainStep({
              player: player.get('id'),
              skill: openerSkill,
              attr: openerAttr,
              result: openerAttr,
            })
          )
        );
      })
      .flatten(1) as List<SkillchainSteps>;

    skillchains = skillchains.concat(
      continueChain(
        playerSkillchains,
        players.filter((p) => p !== player)
      )
    );
  }

  return dedupe(skillchains);
}

// Continues build skillchains from the given list.
// Returns any new skillchains (will not return skillchains already on the list)
function continueChain(
  skillchains: List<SkillchainSteps>,
  players: List<Player>
): List<SkillchainSteps> {
  let newSkillchains = List<SkillchainSteps>();

  for (const skillchain of skillchains) {
    const step = skillchain.last();
    if (!step) {
      console.error('this shouldnt happen i dont think', skillchain.toString());
      continue;
    }
    const openerAttr = step.get('result');
    const closerAttrs = Object.keys(
      AttributeGraph.getConnectedEdges(openerAttr)
    ) as Attribute[];
    for (const closerAttr of closerAttrs) {
      const result = AttributeCombinations.get(List([openerAttr, closerAttr]));
      if (!result) {
        console.error(
          'this shouldnt happen i dont think',
          openerAttr,
          closerAttr
        );
        continue;
      }
      const validClosers = AttributeToSkills.get(
        closerAttr,
        Set<WeaponSkill>()
      );

      for (const player of players) {
        const playerSkills = player.activeWeaponSkills;
        let playerSkillchains = List<SkillchainSteps>();
        const availableClosers = validClosers.intersect(playerSkills);
        for (let closerSkill of availableClosers) {
          playerSkillchains = playerSkillchains.push(
            skillchain.push(
              new SkillchainStep({
                player: player.get('id'),
                skill: closerSkill,
                attr: closerAttr,
                result,
              })
            )
          );
        }
        if (playerSkillchains.size !== 0) {
          playerSkillchains = playerSkillchains.concat(
            continueChain(
              playerSkillchains,
              players.filter((p) => p !== player)
            )
          );
        }
        newSkillchains = newSkillchains.concat(playerSkillchains);
      }
    }
  }
  return newSkillchains;
}

// Create a player-agnostic signature for a skillchain.
// Used to dedupe
function signature(skillchain: SkillchainSteps): string {
  return skillchain.map((step) => step.get('skill')).join(',');
}

function dedupe(skillchains: List<SkillchainSteps>): List<SkillchainSteps> {
  let signatures = Set<string>();
  return skillchains.filter((sc) => {
    const key = signature(sc);
    if (signatures.has(key)) {
      return false;
    }
    signatures = signatures.add(key);
    return true;
  });
}
