import { Autocomplete, FormControl, Stack, TextField } from '@mui/material';
import { styled } from '@mui/system';
import { Image } from 'image-js';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import React, { useCallback, useMemo, useState } from 'react';
import { MapContainer } from 'react-leaflet';
import { NavLink } from 'react-router-dom';
import { getMaps } from '@xi/data/maps/factors';
import { useFetchImage } from '@xi/hooks/useFetchImage';
import { useMapDescriptor } from '@xi/hooks/useMapDescriptor';
import { useCreateUrl, useNavigate } from '@xi/hooks/useMapsNavigate';
import { Descriptor } from '@xi/types/maps/Descriptor';
import { Point } from '@xi/types/maps/Point';
import { DevSettings } from './DevSettings';
import { MapComponent } from './MapComponent';
import { MapContextProvider } from './MapContext';
import { Quests } from './Quests';
import { Settings } from './Settings';
import { Spawns } from './Spawns';
import './styles.css';

interface Style {
  [key: string]: string | Style;
}

function convertHeight(style: Style): Style {
  const result: Style = {};
  if (style.minHeight) {
    result.height = `calc(100% - ${style.minHeight}px)`;
  }
  for (const [key, value] of Object.entries(style)) {
    if (typeof value === 'object') {
      result[key] = convertHeight(value);
    }
  }
  return result;
}

const MapsRoot = styled('div')(({ theme }) => {
  // Transform the height based on the toolbar
  return convertHeight((theme.mixins as any).toolbar);
});

function useHandleClick(): (
  evt: React.SyntheticEvent,
  value: Option | null
) => void {
  const navigate = useNavigate();
  return useCallback(
    (evt, value) => {
      if (value) {
        navigate({
          map: value.name,
        });
      }
    },
    [navigate]
  );
}

interface Option {
  label: string;
  name: string;
}

const mapOptions: Option[] = getMaps().map(({ name, title }) => ({
  label: title,
  name,
}));

interface MapPartsProps {
  descriptor: Descriptor;
}

function MapParts({ descriptor }: MapPartsProps): JSX.Element | null {
  const createUrl = useCreateUrl();

  if (!descriptor) {
    return null;
  }

  const parts = descriptor.getSiblings();

  if (parts.length <= 1) {
    return <></>;
  }

  return (
    <Stack
      className="mappart-base"
      direction="column"
      spacing={1}
      sx={{ m: 1 }}
      width="100%"
    >
      {parts.map((part) => (
        <NavLink
          to={createUrl({
            map: part.name,
          })}
          key={part.name}
        >
          {part.title}
        </NavLink>
      ))}
    </Stack>
  );
}

function useCoords(
  descriptor: Descriptor,
  image: Image
): [(point: Point) => void, Point, string] {
  const [coords, setCoords] = useState(
    new Point({
      map: descriptor.name,
      x: 0,
      y: 0,
    })
  );
  const grid = useMemo(() => coords.toGrid(image), [coords, image]);
  return [setCoords, coords, grid];
}

export function Maps(): JSX.Element {
  const descriptor = useMapDescriptor();
  const { blob, image } = useFetchImage(descriptor.url);
  const selectedOption = useMemo(() => {
    const { name, title } = descriptor;
    return { label: title, name };
  }, [descriptor]);
  const handleClick = useHandleClick();

  const [setCoords, coords, grid] = useCoords(descriptor, image);

  return (
    <MapsRoot className="maps-root">
      <div>
        <FormControl fullWidth>
          <Autocomplete
            isOptionEqualToValue={(option, value) => option.name === value.name}
            value={selectedOption}
            onChange={handleClick}
            options={mapOptions}
            renderInput={(params) => <TextField {...params} label="Map" />}
          />
        </FormControl>
        <Stack spacing={1} sx={{ m: 1 }} direction="column" width="100%">
          <span>
            ({coords.x.toFixed(3)}, {coords.y.toFixed(3)}) ({grid})
          </span>
        </Stack>
        <MapParts descriptor={descriptor} />
        <Quests descriptor={descriptor} />
        <Spawns />
        <Settings />
        <DevSettings />
      </div>
      <div className="maps-leaflet-container">
        <MapContainer
          center={[0, 0]}
          minZoom={0}
          maxZoom={3}
          zoom={0}
          crs={L.CRS.Simple}
          zoomControl={false}
        >
          <MapContextProvider blob={blob} image={image}>
            <MapComponent setCoords={setCoords} />
          </MapContextProvider>
        </MapContainer>
      </div>
    </MapsRoot>
  );
}
