import React from 'react';
import { Rectangle } from 'react-leaflet';
import { useMap } from './MapContext';

// FFXI grid is
// A-O
// 1-15
// A1 top left is 16,15
// A1 bottom right is 48,47
// so dimensions are 32,32?
// Yep that works!
// So our formula is uh
// x0 = 16 + 32*(coord - 1)
// y0 = 15 + 32*(coord - 1)

function alphaCoordToNum(coord: string): number {
  switch (coord.toUpperCase()) {
    case 'A':
    default:
      return 1;
    case 'B':
      return 2;
    case 'C':
      return 3;
    case 'D':
      return 4;
    case 'E':
      return 5;
    case 'F':
      return 6;
    case 'G':
      return 7;
    case 'H':
      return 8;
    case 'I':
      return 9;
    case 'J':
      return 10;
    case 'K':
      return 11;
    case 'L':
      return 12;
    case 'M':
      return 13;
    case 'N':
      return 14;
    case 'O':
      return 15;
  }
}

interface FfxiCoordMarkerProps {
  x: string;
  y: number;
}

export function FfxiCoordMarker({
  x,
  y,
}: FfxiCoordMarkerProps): React.ReactElement {
  const { image } = useMap();
  const xNum = alphaCoordToNum(x);
  if (y < 0 || y > 15) {
    y = 0;
  }
  const topLeft: [number, number] = [
    image.height - (16 + 32 * (y - 1)),
    17 + 32 * (xNum - 1),
  ];
  const botRight: [number, number] = [
    image.height - (16 + 32 * y),
    17 + 32 * xNum,
  ];
  return <Rectangle bounds={[topLeft, botRight]} />;
}
