import { Point } from './Point';

export interface TriggerArea {
  map: string;
  label?: string;
  first: Point;
  // second point can be optional
  // If it's not given, area is a circle/cylinder and radius is defined by first.z
  second?: Point;
}

export type Location = Point | TriggerArea;

export function isPoint(location: Location): location is Point {
  return (location as Point).x !== undefined;
}

export function isTriggerArea(location: Location): location is TriggerArea {
  return (location as TriggerArea).first !== undefined;
}

export interface QuestCategory {
  note: string;
  steps: QuestStep[];
}

export interface QuestPoint {
  id: string;
  location: Location[];
  note: string;
}

export type QuestStep = QuestCategory | QuestPoint;

export interface Quest {
  area: number | string;
  // We modify the raw id to make a unique id
  id: string;
  name: string;
  prereqs: string;
  requirements: string;
  reward: string;
  steps: QuestStep[];
}

export function isQuestCategory(
  step: QuestStep | undefined
): step is QuestCategory {
  return (step as QuestCategory)?.steps !== undefined;
}

export function isQuestPoint(step: QuestStep | undefined): step is QuestPoint {
  return (step as QuestPoint)?.location !== undefined;
}
