export enum Weapon {
  AXE = 'AXE',
  GREAT_AXE = 'GREAT_AXE',
  ARCHERY = 'ARCHERY',
  CLUB = 'CLUB',
  DAGGER = 'DAGGER',
  HAND_TO_HAND = 'HAND_TO_HAND',
  KATANA = 'KATANA',
  GREAT_KATANA = 'GREAT_KATANA',
  MARKSMANSHIP = 'MARKSMANSHIP',
  POLEARM = 'POLEARM',
  SCYTHE = 'SCYTHE',
  STAFF = 'STAFF',
  SWORD = 'SWORD',
  GREAT_SWORD = 'GREAT_SWORD',
  BLOOD_PACT = 'BLOOD_PACT',
}
