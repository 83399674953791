import altaieuUrl from '@xi/img/remapster/altaieu.png';
import altarRoomUrl from '@xi/img/remapster/altar_room.png';
import attohwaChasmUrl from '@xi/img/remapster/attohwa_chasm.png';
import bastokMarketsUrl from '@xi/img/remapster/bastok_markets.png';
import bastokMinesUrl from '@xi/img/remapster/bastok_mines.png';
import batalliaDownsUrl from '@xi/img/remapster/batallia_downs.png';
import beadeaux0Url from '@xi/img/remapster/beadeaux_0.png';
import beadeaux1Url from '@xi/img/remapster/beadeaux_1.png';
import beaucedineGlacierUrl from '@xi/img/remapster/beaucedine_glacier.png';
import behemothsDominionUrl from '@xi/img/remapster/behemoths_dominion.png';
import bibikiBay0Url from '@xi/img/remapster/bibiki_bay_0.png';
import bibikiBay1Url from '@xi/img/remapster/bibiki_bay_1.png';
import bostaunieuxOubliette0Url from '@xi/img/remapster/bostaunieux_oubliette_0.png';
import bostaunieuxOubliette1Url from '@xi/img/remapster/bostaunieux_oubliette_1.png';
import buburimuPeninsulaUrl from '@xi/img/remapster/buburimu_peninsula.png';
import castleOztroja0Url from '@xi/img/remapster/castle_oztroja_0.png';
import castleOztroja1Url from '@xi/img/remapster/castle_oztroja_1.png';
import castleOztroja2Url from '@xi/img/remapster/castle_oztroja_2.png';
import castleOztroja3Url from '@xi/img/remapster/castle_oztroja_3.png';
import castleOztroja4Url from '@xi/img/remapster/castle_oztroja_4.png';
import castleOztroja5Url from '@xi/img/remapster/castle_oztroja_5.png';
import castleOztroja6Url from '@xi/img/remapster/castle_oztroja_6.png';
import castleZvahlBaileys0Url from '@xi/img/remapster/castle_zvahl_baileys_0.png';
import castleZvahlBaileys1Url from '@xi/img/remapster/castle_zvahl_baileys_1.png';
import castleZvahlBaileys2Url from '@xi/img/remapster/castle_zvahl_baileys_2.png';
import castleZvahlBaileys3Url from '@xi/img/remapster/castle_zvahl_baileys_3.png';
import castleZvahlKeep0Url from '@xi/img/remapster/castle_zvahl_keep_0.png';
import castleZvahlKeep1Url from '@xi/img/remapster/castle_zvahl_keep_1.png';
import castleZvahlKeep2Url from '@xi/img/remapster/castle_zvahl_keep_2.png';
import castleZvahlKeep3Url from '@xi/img/remapster/castle_zvahl_keep_3.png';
import chateaudOraguilleUrl from '@xi/img/remapster/chateau_doraguille.png';
import crawlersNest0Url from '@xi/img/remapster/crawlers_nest_0.png';
import crawlersNest1Url from '@xi/img/remapster/crawlers_nest_1.png';
import crawlersNest2Url from '@xi/img/remapster/crawlers_nest_2.png';
import dangrufWadi0Url from '@xi/img/remapster/dangruf_wadi.png';
import davoiUrl from '@xi/img/remapster/davoi.png';
import eastRonfaureUrl from '@xi/img/remapster/east_ronfaure.png';
import eastSarutabarutaUrl from '@xi/img/remapster/east_sarutabaruta.png';
import fortGhelsbaUrl from '@xi/img/remapster/fort_ghelsba.png';
import garlaigeCitadel0Url from '@xi/img/remapster/garlaige_citadel_0.png';
import garlaigeCitadel1Url from '@xi/img/remapster/garlaige_citadel_1.png';
import garlaigeCitadel2Url from '@xi/img/remapster/garlaige_citadel_2.png';
import garlaigeCitadel3Url from '@xi/img/remapster/garlaige_citadel_3.png';
import ghelsbaOutpostUrl from '@xi/img/remapster/ghelsba_outpost_0.png';
import giddeus0Url from '@xi/img/remapster/giddeus_0.png';
import giddeus1Url from '@xi/img/remapster/giddeus_1.png';
import gusgenMines0Url from '@xi/img/remapster/gusgen_mines_0.png';
import gusgenMines1Url from '@xi/img/remapster/gusgen_mines_1.png';
import gusgenMines2Url from '@xi/img/remapster/gusgen_mines_2.png';
import gusgenMines3Url from '@xi/img/remapster/gusgen_mines_3.png';
import gustavTunnel0Url from '@xi/img/remapster/gustav_tunnel_0.png';
import gustavTunnel1Url from '@xi/img/remapster/gustav_tunnel_1.png';
import innerHorutotoRuins0Url from '@xi/img/remapster/inner_horutoto_ruins_0.png';
import innerHorutotoRuins1Url from '@xi/img/remapster/inner_horutoto_ruins_1.png';
import innerHorutotoRuins2Url from '@xi/img/remapster/inner_horutoto_ruins_2.png';
import innerHorutotoRuins3Url from '@xi/img/remapster/inner_horutoto_ruins_3.png';
import jugnerForestUrl from '@xi/img/remapster/jugner_forest.png';
import konschtatHighlandsUrl from '@xi/img/remapster/konschtat_highlands.png';
import korrolokaTunnel0Url from '@xi/img/remapster/korroloka_tunnel_0.png';
import korrolokaTunnel1Url from '@xi/img/remapster/korroloka_tunnel_1.png';
import korrolokaTunnel2Url from '@xi/img/remapster/korroloka_tunnel_2.png';
import korrolokaTunnel3Url from '@xi/img/remapster/korroloka_tunnel_3.png';
import korrolokaTunnel4Url from '@xi/img/remapster/korroloka_tunnel_4.png';
import kuftalTunnel0Url from '@xi/img/remapster/kuftal_tunnel_0.png';
import kuftalTunnel1Url from '@xi/img/remapster/kuftal_tunnel_1.png';
import kuftalTunnel2Url from '@xi/img/remapster/kuftal_tunnel_2.png';
import kuftalTunnel3Url from '@xi/img/remapster/kuftal_tunnel_3.png';
import laTheinePlateauUrl from '@xi/img/remapster/la_theine_plateau.png';
import lowerDelkfuttsTower0Url from '@xi/img/remapster/lower_delkfutts_tower_0.png';
import lowerDelkfuttsTower1Url from '@xi/img/remapster/lower_delkfutts_tower_1.png';
import lowerDelkfuttsTower2Url from '@xi/img/remapster/lower_delkfutts_tower_2.png';
import lowerDelkfuttsTower3Url from '@xi/img/remapster/lower_delkfutts_tower_3.png';
import lowerJeunoUrl from '@xi/img/remapster/lower_jeuno.png';
import lufaiseMeadowsUrl from '@xi/img/remapster/lufaise_meadows.png';
import mazeOfShakhrami0Url from '@xi/img/remapster/maze_of_shakhrami_0.png';
import mazeOfShakhrami1Url from '@xi/img/remapster/maze_of_shakhrami_1.png';
import meriphataudMountainsUrl from '@xi/img/remapster/meriphataud_mountains.png';
import metalworksUrl from '@xi/img/remapster/metalworks.png';
import mhauraUrl from '@xi/img/remapster/mhaura.png';
import middleDelkfuttsTower0Url from '@xi/img/remapster/middle_delkfutts_tower_0.png';
import middleDelkfuttsTower1Url from '@xi/img/remapster/middle_delkfutts_tower_1.png';
import middleDelkfuttsTower2Url from '@xi/img/remapster/middle_delkfutts_tower_2.png';
import middleDelkfuttsTower3Url from '@xi/img/remapster/middle_delkfutts_tower_3.png';
import middleDelkfuttsTower4Url from '@xi/img/remapster/middle_delkfutts_tower_4.png';
import middleDelkfuttsTower5Url from '@xi/img/remapster/middle_delkfutts_tower_5.png';
import misareauxCoastUrl from '@xi/img/remapster/misareaux_coast.png';
import monasticCavernUrl from '@xi/img/remapster/monastic_cavern.png';
import norgUrl from '@xi/img/remapster/norg.png';
import northGustabergUrl from '@xi/img/remapster/north_gustaberg.png';
import northernSandoriaUrl from '@xi/img/remapster/northern_san_doria.png';
import palboroughMines0Url from '@xi/img/remapster/palborough_mines_0.png';
import palboroughMines1Url from '@xi/img/remapster/palborough_mines_1.png';
import palboroughMines2Url from '@xi/img/remapster/palborough_mines_2.png';
import pashhowMarshlandsUrl from '@xi/img/remapster/pashhow_marshlands.png';
import portBastokUrl from '@xi/img/remapster/port_bastok.png';
import portJeunoUrl from '@xi/img/remapster/port_jeuno.png';
import portSandoriaUrl from '@xi/img/remapster/port_san_doria.png';
import portWindurstUrl from '@xi/img/remapster/port_windurst.png';
import promyvionDemUrl from '@xi/img/remapster/promyvion_dem.png';
import promyvionHollaUrl from '@xi/img/remapster/promyvion_holla.png';
import promyvionMeaUrl from '@xi/img/remapster/promyvion_mea.png';
import promyvionVahzlUrl from '@xi/img/remapster/promyvion_vahzl.png';
import psoxja0Url from '@xi/img/remapster/psoxja_0.png';
import psoxja1Url from '@xi/img/remapster/psoxja_1.png';
import psoxja10Url from '@xi/img/remapster/psoxja_10.png';
import psoxja11Url from '@xi/img/remapster/psoxja_11.png';
import psoxja13Url from '@xi/img/remapster/psoxja_13.png';
import psoxja15Url from '@xi/img/remapster/psoxja_15.png';
import psoxja3Url from '@xi/img/remapster/psoxja_3.png';
import psoxja4Url from '@xi/img/remapster/psoxja_4.png';
import psoxja5Url from '@xi/img/remapster/psoxja_5.png';
import psoxja7Url from '@xi/img/remapster/psoxja_7.png';
import psoxja8Url from '@xi/img/remapster/psoxja_8.png';
import qufimIslandUrl from '@xi/img/remapster/qufim_island.png';
import qulunDomeUrl from '@xi/img/remapster/qulun_dome.png';
import riverneSiteA010Url from '@xi/img/remapster/riverne_site_a01_0.png';
import riverneSiteA011Url from '@xi/img/remapster/riverne_site_a01_1.png';
import riverneSiteB010Url from '@xi/img/remapster/riverne_site_b01_0.png';
import riverneSiteB011Url from '@xi/img/remapster/riverne_site_b01_1.png';
import rolanberryFieldsUrl from '@xi/img/remapster/rolanberry_fields.png';
import ruaunGardensUrl from '@xi/img/remapster/ruaun_gardens.png';
import ruludeGardensUrl from '@xi/img/remapster/rulude_gardens.png';
import sauromugueChampaignUrl from '@xi/img/remapster/sauromugue_champaign.png';
import seaSerpentGrotto0Url from '@xi/img/remapster/sea_serpent_grotto_0.png';
import seaSerpentGrotto1Url from '@xi/img/remapster/sea_serpent_grotto_1.png';
import seaSerpentGrotto2Url from '@xi/img/remapster/sea_serpent_grotto_2.png';
import seaSerpentGrotto3Url from '@xi/img/remapster/sea_serpent_grotto_3.png';
import seaSerpentGrotto4Url from '@xi/img/remapster/sea_serpent_grotto_4.png';
import selbinaUrl from '@xi/img/remapster/selbina.png';
import southGustabergUrl from '@xi/img/remapster/south_gustaberg.png';
import southernSandoriaUrl from '@xi/img/remapster/southern_san_doria.png';
import tahrongiCanyonUrl from '@xi/img/remapster/tahrongi_canyon.png';
import tavnazianSafehold0Url from '@xi/img/remapster/tavnazian_safehold_0.png';
import tavnazianSafehold1Url from '@xi/img/remapster/tavnazian_safehold_1.png';
import tavnazianSafehold2Url from '@xi/img/remapster/tavnazian_safehold_2.png';
import templeOfUggalepih0Url from '@xi/img/remapster/temple_of_uggalepih_0.png';
import templeOfUggalepih1Url from '@xi/img/remapster/temple_of_uggalepih_1.png';
import templeOfUggalepih2Url from '@xi/img/remapster/temple_of_uggalepih_2.png';
import templeOfUggalepih3Url from '@xi/img/remapster/temple_of_uggalepih_3.png';
import theBoyahdaTree0Url from '@xi/img/remapster/the_boyahda_tree_0.png';
import theBoyahdaTree1Url from '@xi/img/remapster/the_boyahda_tree_1.png';
import theBoyahdaTree2Url from '@xi/img/remapster/the_boyahda_tree_2.png';
import theBoyahdaTree3Url from '@xi/img/remapster/the_boyahda_tree_3.png';
import theEldiemeNecropolis0Url from '@xi/img/remapster/the_eldieme_necropolis_0.png';
import theEldiemeNecropolis1Url from '@xi/img/remapster/the_eldieme_necropolis_1.png';
import theEldiemeNecropolis2Url from '@xi/img/remapster/the_eldieme_necropolis_2.png';
import theSanctuaryOfZitahUrl from '@xi/img/remapster/the_sanctuary_of_zitah.png';
import uleguerandRangeUrl from '@xi/img/remapster/uleguerand_range_0.png';
import upperDelkfuttsTower0Url from '@xi/img/remapster/upper_delkfutts_tower_0.png';
import upperDelkfuttsTower1Url from '@xi/img/remapster/upper_delkfutts_tower_1.png';
import upperDelkfuttsTower2Url from '@xi/img/remapster/upper_delkfutts_tower_2.png';
import upperDelkfuttsTower3Url from '@xi/img/remapster/upper_delkfutts_tower_3.png';
import upperDelkfuttsTower4Url from '@xi/img/remapster/upper_delkfutts_tower_4.png';
import upperDelkfuttsTower5Url from '@xi/img/remapster/upper_delkfutts_tower_5.png';
import upperJeunoUrl from '@xi/img/remapster/upper_jeuno.png';
import valkurmDunesUrl from '@xi/img/remapster/valkurm_dunes.png';
import westRonfaureUrl from '@xi/img/remapster/west_ronfaure.png';
import westSarutabarutaUrl from '@xi/img/remapster/west_sarutabaruta.png';
import windurstWallsUrl from '@xi/img/remapster/windurst_walls.png';
import windurstWaters0Url from '@xi/img/remapster/windurst_waters_0.png';
import windurstWaters1Url from '@xi/img/remapster/windurst_waters_1.png';
import windurstWoodsUrl from '@xi/img/remapster/windurst_woods.png';
import xarcabardUrl from '@xi/img/remapster/xarcabard.png';
import yhoatorJungleUrl from '@xi/img/remapster/yhoator_jungle.png';
import yughottGrotto0Url from '@xi/img/remapster/yughott_grotto_0.png';
import yughottGrotto1Url from '@xi/img/remapster/yughott_grotto_1.png';
import yuhtungaJungleUrl from '@xi/img/remapster/yuhtunga_jungle.png';
import zeruhnMinesUrl from '@xi/img/remapster/zeruhn_mines.png';

const attribution = '© Square Enix | © Remapster';

export interface MapImage {
  attribution: string;
  url: string;
}

export const mapImages: Record<string, MapImage> = {
  altaieu: {
    attribution,
    url: altaieuUrl,
  },
  altar_room: {
    attribution,
    url: altarRoomUrl,
  },
  attohwa_chasm: {
    attribution,
    url: attohwaChasmUrl,
  },
  bastok_markets: {
    attribution,
    url: bastokMarketsUrl,
  },
  bastok_mines: {
    attribution,
    url: bastokMinesUrl,
  },
  batallia_downs: {
    attribution,
    url: batalliaDownsUrl,
  },
  beadeaux_0: {
    attribution,
    url: beadeaux0Url,
  },
  beadeaux_1: {
    attribution,
    url: beadeaux1Url,
  },
  beaucedine_glacier: {
    attribution,
    url: beaucedineGlacierUrl,
  },
  behemoths_dominion: {
    attribution,
    url: behemothsDominionUrl,
  },
  bibiki_bay_0: {
    attribution,
    url: bibikiBay0Url,
  },
  bibiki_bay_1: {
    attribution,
    url: bibikiBay1Url,
  },
  bostaunieux_oubliette_0: {
    attribution,
    url: bostaunieuxOubliette0Url,
  },
  bostaunieux_oubliette_1: {
    attribution,
    url: bostaunieuxOubliette1Url,
  },
  buburimu_peninsula: {
    attribution,
    url: buburimuPeninsulaUrl,
  },
  castle_oztroja_0: {
    attribution,
    url: castleOztroja0Url,
  },
  castle_oztroja_1: {
    attribution,
    url: castleOztroja1Url,
  },
  castle_oztroja_2: {
    attribution,
    url: castleOztroja2Url,
  },
  castle_oztroja_3: {
    attribution,
    url: castleOztroja3Url,
  },
  castle_oztroja_4: {
    attribution,
    url: castleOztroja4Url,
  },
  castle_oztroja_5: {
    attribution,
    url: castleOztroja5Url,
  },
  castle_oztroja_6: {
    attribution,
    url: castleOztroja6Url,
  },
  castle_zvahl_baileys_0: {
    attribution,
    url: castleZvahlBaileys0Url,
  },
  castle_zvahl_baileys_1: {
    attribution,
    url: castleZvahlBaileys1Url,
  },
  castle_zvahl_baileys_2: {
    attribution,
    url: castleZvahlBaileys2Url,
  },
  castle_zvahl_baileys_3: {
    attribution,
    url: castleZvahlBaileys3Url,
  },
  castle_zvahl_keep_0: {
    attribution,
    url: castleZvahlKeep0Url,
  },
  castle_zvahl_keep_1: {
    attribution,
    url: castleZvahlKeep1Url,
  },
  castle_zvahl_keep_2: {
    attribution,
    url: castleZvahlKeep2Url,
  },
  castle_zvahl_keep_3: {
    attribution,
    url: castleZvahlKeep3Url,
  },
  chateau_doraguille: {
    attribution,
    url: chateaudOraguilleUrl,
  },
  crawlers_nest_0: {
    attribution,
    url: crawlersNest0Url,
  },
  crawlers_nest_1: {
    attribution,
    url: crawlersNest1Url,
  },
  crawlers_nest_2: {
    attribution,
    url: crawlersNest2Url,
  },
  dangruf_wadi_0: {
    attribution,
    url: dangrufWadi0Url,
  },
  davoi: {
    attribution,
    url: davoiUrl,
  },
  east_ronfaure: {
    attribution,
    url: eastRonfaureUrl,
  },
  east_sarutabaruta: {
    attribution,
    url: eastSarutabarutaUrl,
  },
  fort_ghelsba: {
    attribution,
    url: fortGhelsbaUrl,
  },
  garlaige_citadel_0: {
    attribution,
    url: garlaigeCitadel0Url,
  },
  garlaige_citadel_1: {
    attribution,
    url: garlaigeCitadel1Url,
  },
  garlaige_citadel_2: {
    attribution,
    url: garlaigeCitadel2Url,
  },
  garlaige_citadel_3: {
    attribution,
    url: garlaigeCitadel3Url,
  },
  ghelsba_outpost: {
    attribution,
    url: ghelsbaOutpostUrl,
  },
  giddeus_0: {
    attribution,
    url: giddeus0Url,
  },
  giddeus_1: {
    attribution,
    url: giddeus1Url,
  },
  gusgen_mines_0: {
    attribution,
    url: gusgenMines0Url,
  },
  gusgen_mines_1: {
    attribution,
    url: gusgenMines1Url,
  },
  gusgen_mines_2: {
    attribution,
    url: gusgenMines2Url,
  },
  gusgen_mines_3: {
    attribution,
    url: gusgenMines3Url,
  },
  gustav_tunnel_0: {
    attribution,
    url: gustavTunnel0Url,
  },
  gustav_tunnel_1: {
    attribution,
    url: gustavTunnel1Url,
  },
  inner_horutoto_ruins_0: {
    attribution,
    url: innerHorutotoRuins0Url,
  },
  inner_horutoto_ruins_1: {
    attribution,
    url: innerHorutotoRuins1Url,
  },
  inner_horutoto_ruins_2: {
    attribution,
    url: innerHorutotoRuins2Url,
  },
  inner_horutoto_ruins_3: {
    attribution,
    url: innerHorutotoRuins3Url,
  },
  jugner_forest: {
    attribution,
    url: jugnerForestUrl,
  },
  konschtat_highlands: {
    attribution,
    url: konschtatHighlandsUrl,
  },
  korroloka_tunnel_0: {
    attribution,
    url: korrolokaTunnel0Url,
  },
  korroloka_tunnel_1: {
    attribution,
    url: korrolokaTunnel1Url,
  },
  korroloka_tunnel_2: {
    attribution,
    url: korrolokaTunnel2Url,
  },
  korroloka_tunnel_3: {
    attribution,
    url: korrolokaTunnel3Url,
  },
  korroloka_tunnel_4: {
    attribution,
    url: korrolokaTunnel4Url,
  },
  kuftal_tunnel_0: {
    attribution,
    url: kuftalTunnel0Url,
  },
  kuftal_tunnel_1: {
    attribution,
    url: kuftalTunnel1Url,
  },
  kuftal_tunnel_2: {
    attribution,
    url: kuftalTunnel2Url,
  },
  kuftal_tunnel_3: {
    attribution,
    url: kuftalTunnel3Url,
  },
  la_theine_plateau: {
    attribution,
    url: laTheinePlateauUrl,
  },
  lower_delkfutts_tower_0: {
    attribution,
    url: lowerDelkfuttsTower0Url,
  },
  lower_delkfutts_tower_1: {
    attribution,
    url: lowerDelkfuttsTower1Url,
  },
  lower_delkfutts_tower_2: {
    attribution,
    url: lowerDelkfuttsTower2Url,
  },
  lower_delkfutts_tower_3: {
    attribution,
    url: lowerDelkfuttsTower3Url,
  },
  lower_jeuno: {
    attribution,
    url: lowerJeunoUrl,
  },
  lufaise_meadows: {
    attribution,
    url: lufaiseMeadowsUrl,
  },
  maze_of_shakhrami_0: {
    attribution,
    url: mazeOfShakhrami0Url,
  },
  maze_of_shakhrami_1: {
    attribution,
    url: mazeOfShakhrami1Url,
  },
  meriphataud_mountains: {
    attribution,
    url: meriphataudMountainsUrl,
  },
  metalworks: {
    attribution,
    url: metalworksUrl,
  },
  mhaura: {
    attribution,
    url: mhauraUrl,
  },
  middle_delkfutts_tower_0: {
    attribution,
    url: middleDelkfuttsTower0Url,
  },
  middle_delkfutts_tower_1: {
    attribution,
    url: middleDelkfuttsTower1Url,
  },
  middle_delkfutts_tower_2: {
    attribution,
    url: middleDelkfuttsTower2Url,
  },
  middle_delkfutts_tower_3: {
    attribution,
    url: middleDelkfuttsTower3Url,
  },
  middle_delkfutts_tower_4: {
    attribution,
    url: middleDelkfuttsTower4Url,
  },
  middle_delkfutts_tower_5: {
    attribution,
    url: middleDelkfuttsTower5Url,
  },
  misareaux_coast: {
    attribution,
    url: misareauxCoastUrl,
  },
  monastic_cavern: {
    attribution,
    url: monasticCavernUrl,
  },
  norg: {
    attribution,
    url: norgUrl,
  },
  northern_san_doria: {
    attribution,
    url: northernSandoriaUrl,
  },
  north_gustaberg: {
    attribution,
    url: northGustabergUrl,
  },
  palborough_mines_0: {
    attribution,
    url: palboroughMines0Url,
  },
  palborough_mines_1: {
    attribution,
    url: palboroughMines1Url,
  },
  palborough_mines_2: {
    attribution,
    url: palboroughMines2Url,
  },
  pashhow_marshlands: {
    attribution,
    url: pashhowMarshlandsUrl,
  },
  port_bastok: {
    attribution,
    url: portBastokUrl,
  },
  port_jeuno: {
    attribution,
    url: portJeunoUrl,
  },
  port_san_doria: {
    attribution,
    url: portSandoriaUrl,
  },
  port_windurst: {
    attribution,
    url: portWindurstUrl,
  },
  promyvion_dem: {
    attribution,
    url: promyvionDemUrl,
  },
  promyvion_holla: {
    attribution,
    url: promyvionHollaUrl,
  },
  promyvion_mea: {
    attribution,
    url: promyvionMeaUrl,
  },
  promyvion_vahzl: {
    attribution,
    url: promyvionVahzlUrl,
  },
  psoxja_0: {
    attribution,
    url: psoxja0Url,
  },
  psoxja_10: {
    attribution,
    url: psoxja10Url,
  },
  psoxja_11: {
    attribution,
    url: psoxja11Url,
  },
  psoxja_13: {
    attribution,
    url: psoxja13Url,
  },
  psoxja_15: {
    attribution,
    url: psoxja15Url,
  },
  psoxja_1: {
    attribution,
    url: psoxja1Url,
  },
  psoxja_3: {
    attribution,
    url: psoxja3Url,
  },
  psoxja_4: {
    attribution,
    url: psoxja4Url,
  },
  psoxja_5: {
    attribution,
    url: psoxja5Url,
  },
  psoxja_7: {
    attribution,
    url: psoxja7Url,
  },
  psoxja_8: {
    attribution,
    url: psoxja8Url,
  },
  qufim_island: {
    attribution,
    url: qufimIslandUrl,
  },
  qulun_dome: {
    attribution,
    url: qulunDomeUrl,
  },
  riverne_site_a01_0: {
    attribution,
    url: riverneSiteA010Url,
  },
  riverne_site_a01_1: {
    attribution,
    url: riverneSiteA011Url,
  },
  riverne_site_b01_0: {
    attribution,
    url: riverneSiteB010Url,
  },
  riverne_site_b01_1: {
    attribution,
    url: riverneSiteB011Url,
  },
  rolanberry_fields: {
    attribution,
    url: rolanberryFieldsUrl,
  },
  ruaun_gardens: {
    attribution,
    url: ruaunGardensUrl,
  },
  rulude_gardens: {
    attribution,
    url: ruludeGardensUrl,
  },
  sauromugue_champaign: {
    attribution,
    url: sauromugueChampaignUrl,
  },
  sea_serpent_grotto_0: {
    attribution,
    url: seaSerpentGrotto0Url,
  },
  sea_serpent_grotto_1: {
    attribution,
    url: seaSerpentGrotto1Url,
  },
  sea_serpent_grotto_2: {
    attribution,
    url: seaSerpentGrotto2Url,
  },
  sea_serpent_grotto_3: {
    attribution,
    url: seaSerpentGrotto3Url,
  },
  sea_serpent_grotto_4: {
    attribution,
    url: seaSerpentGrotto4Url,
  },
  selbina: {
    attribution,
    url: selbinaUrl,
  },
  southern_san_doria: {
    attribution,
    url: southernSandoriaUrl,
  },
  south_gustaberg: {
    attribution,
    url: southGustabergUrl,
  },
  tahrongi_canyon: {
    attribution,
    url: tahrongiCanyonUrl,
  },
  tavnazian_safehold_0: {
    attribution,
    url: tavnazianSafehold0Url,
  },
  tavnazian_safehold_1: {
    attribution,
    url: tavnazianSafehold1Url,
  },
  tavnazian_safehold_2: {
    attribution,
    url: tavnazianSafehold2Url,
  },
  temple_of_uggalepih_0: {
    attribution,
    url: templeOfUggalepih0Url,
  },
  temple_of_uggalepih_1: {
    attribution,
    url: templeOfUggalepih1Url,
  },
  temple_of_uggalepih_2: {
    attribution,
    url: templeOfUggalepih2Url,
  },
  temple_of_uggalepih_3: {
    attribution,
    url: templeOfUggalepih3Url,
  },
  the_boyahda_tree_0: {
    attribution,
    url: theBoyahdaTree0Url,
  },
  the_boyahda_tree_1: {
    attribution,
    url: theBoyahdaTree1Url,
  },
  the_boyahda_tree_2: {
    attribution,
    url: theBoyahdaTree2Url,
  },
  the_boyahda_tree_3: {
    attribution,
    url: theBoyahdaTree3Url,
  },
  the_eldieme_necropolis_0: {
    attribution,
    url: theEldiemeNecropolis0Url,
  },
  the_eldieme_necropolis_1: {
    attribution,
    url: theEldiemeNecropolis1Url,
  },
  the_eldieme_necropolis_2: {
    attribution,
    url: theEldiemeNecropolis2Url,
  },
  the_sanctuary_of_zitah: {
    attribution,
    url: theSanctuaryOfZitahUrl,
  },
  uleguerand_range: {
    attribution,
    url: uleguerandRangeUrl,
  },
  upper_delkfutts_tower_0: {
    attribution,
    url: upperDelkfuttsTower0Url,
  },
  upper_delkfutts_tower_1: {
    attribution,
    url: upperDelkfuttsTower1Url,
  },
  upper_delkfutts_tower_2: {
    attribution,
    url: upperDelkfuttsTower2Url,
  },
  upper_delkfutts_tower_3: {
    attribution,
    url: upperDelkfuttsTower3Url,
  },
  upper_delkfutts_tower_4: {
    attribution,
    url: upperDelkfuttsTower4Url,
  },
  upper_delkfutts_tower_5: {
    attribution,
    url: upperDelkfuttsTower5Url,
  },
  upper_jeuno: {
    attribution,
    url: upperJeunoUrl,
  },
  valkurm_dunes: {
    attribution,
    url: valkurmDunesUrl,
  },
  west_ronfaure: {
    attribution,
    url: westRonfaureUrl,
  },
  west_sarutabaruta: {
    attribution,
    url: westSarutabarutaUrl,
  },
  windurst_walls: {
    attribution,
    url: windurstWallsUrl,
  },
  windurst_waters_0: {
    attribution,
    url: windurstWaters0Url,
  },
  windurst_waters_1: {
    attribution,
    url: windurstWaters1Url,
  },
  windurst_woods: {
    attribution,
    url: windurstWoodsUrl,
  },
  xarcabard: {
    attribution,
    url: xarcabardUrl,
  },
  yhoator_jungle: {
    attribution,
    url: yhoatorJungleUrl,
  },
  yughott_grotto_0: {
    attribution,
    url: yughottGrotto0Url,
  },
  yughott_grotto_1: {
    attribution,
    url: yughottGrotto1Url,
  },
  yuhtunga_jungle: {
    attribution,
    url: yuhtungaJungleUrl,
  },
  zeruhn_mines: {
    attribution,
    url: zeruhnMinesUrl,
  },
};
