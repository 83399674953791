import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import FormControl from '@mui/material/FormControl';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import React, { useCallback, useMemo } from 'react';
import { useToggle } from 'usehooks-ts';
import { quests } from '@xi/data/maps/quests';
import {
  useMapDescriptor,
  useMapDescriptors,
} from '@xi/hooks/useMapDescriptor';
import { useNavigate } from '@xi/hooks/useMapsNavigate';
import { useQuest } from '@xi/hooks/useQuest';
import { Descriptor } from '@xi/types/maps/Descriptor';
import {
  isQuestPoint,
  QuestCategory,
  QuestPoint,
  QuestStep,
} from '@xi/types/maps/Quest';

interface QuestsProps {
  descriptor: Descriptor;
}

interface Option {
  id: string;
  label: string;
}

function useHandleClick(): (
  evt: React.SyntheticEvent,
  value: Option | null
) => void {
  const navigate = useNavigate();
  return useCallback(
    (evt, value) => {
      if (value) {
        navigate({ q: value.id });
      } else {
        navigate({ q: undefined, qs: undefined });
      }
    },
    [navigate]
  );
}

export function Quests({
  descriptor,
}: QuestsProps): React.ReactElement<QuestsProps, typeof Quests> {
  const handleClick = useHandleClick();

  const options = useMemo(
    () => quests.map((quest) => ({ id: quest.id, label: quest.name })),
    []
  );

  const [quest] = useQuest();
  const selectedQuest = quest ? { id: quest.id, label: quest.name } : null;

  return (
    <>
      <FormControl sx={{ pt: 1 }} fullWidth>
        <Autocomplete
          isOptionEqualToValue={(option, value) => option.id === value?.id}
          value={selectedQuest}
          onChange={handleClick}
          options={options}
          renderInput={(params) => <TextField {...params} label="Quest" />}
        />
      </FormControl>
      <QuestStepsComponent depth={0} steps={quest?.steps ?? []} />
    </>
  );
}

interface QuestStepsProps {
  depth: number;
  steps: QuestStep[];
}

// Component that renders a list of steps as a list
function QuestStepsComponent({
  depth,
  steps,
}: QuestStepsProps): React.ReactElement<
  QuestStepsProps,
  typeof QuestStepsComponent
> {
  return (
    <List dense disablePadding>
      {steps.map((step) => (
        <QuestStepComponent depth={depth} step={step} />
      ))}
    </List>
  );
}

interface QuestStepProps {
  depth: number;
  step: QuestStep;
}

function QuestStepComponent({
  depth,
  step,
}: QuestStepProps): React.ReactElement<
  QuestStepProps,
  typeof QuestStepComponent
> {
  if (isQuestPoint(step)) {
    return <QuestPointComponent depth={depth} step={step} />;
  }
  return <QuestCategoryComponent depth={depth} step={step} />;
}

interface QuestPointProps {
  depth: number;
  step: QuestPoint;
}

function QuestPointComponent({
  depth,
  step,
}: QuestPointProps): React.ReactElement<
  QuestPointProps,
  typeof QuestPointComponent
> {
  const names = useMemo(
    () => [...new Set(step.location.map((loc) => loc.map))].sort(),
    [step]
  );
  const descriptors = useMapDescriptors(names);
  const [, , stepNum] = useQuest();
  const descriptor = useMapDescriptor();

  return (
    <>
      <ListItemButton className="quest-step" disabled sx={{ pl: 0 }}>
        <ListItemText sx={{ pl: depth }}>{step.note}</ListItemText>
      </ListItemButton>
      <List className="quest-link" dense disablePadding>
        {descriptors.map((desc) => (
          <QuestLinkComponent
            active={stepNum === step.id && desc.name === descriptor.name}
            depth={depth + 1}
            descriptor={desc}
            key={desc.name}
            step={step}
          />
        ))}
      </List>
    </>
  );
}

function useQuestLink(map: string, step: string): () => void {
  const navigate = useNavigate();
  return useCallback(() => navigate({ map, qs: step }), [map, navigate, step]);
}

interface QuestLinkProps {
  active: boolean;
  depth: number;
  descriptor: Descriptor;
  step: QuestPoint;
}

function QuestLinkComponent({
  active,
  depth,
  descriptor,
  step,
}: QuestLinkProps): React.ReactElement<
  QuestLinkProps,
  typeof QuestLinkComponent
> {
  const handleClick = useQuestLink(descriptor.name, step.id);

  return (
    <ListItemButton
      className="quest-link"
      onClick={handleClick}
      selected={active}
      sx={{ pl: depth, color: 'var(--cyan)' }}
    >
      <ListItemText>{descriptor.title}</ListItemText>
    </ListItemButton>
  );
}

interface QuestCategoryProps {
  depth: number;
  step: QuestCategory;
}

function QuestCategoryComponent({
  depth,
  step,
}: QuestCategoryProps): React.ReactElement<
  QuestCategoryProps,
  typeof QuestCategoryComponent
> {
  const [show, toggle] = useToggle(true);
  return (
    <>
      <ListItemButton sx={{ pl: depth }} onClick={toggle}>
        <ListItemText>{step.note}</ListItemText>
        {show ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={show}>
        <Box className="quest-category">
          <QuestStepsComponent depth={depth + 1} steps={step.steps} />
        </Box>
      </Collapse>
    </>
  );
}
