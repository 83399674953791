import { Record } from 'immutable';
import { Point } from './Point';

interface IZoneline {
  id: string;
  dst: Point;
  src: Point;
}

export class Zoneline extends Record<IZoneline>({
  id: '-',
  dst: new Point({ map: 'no_map', x: 0, y: 0 }),
  src: new Point({ map: 'no_map', x: 0, y: 0 }),
}) {}
