import { List, Record } from 'immutable';
import { Attribute } from './Attribute';
import { WeaponSkill } from './WeaponSkill';

interface ISkillchainStep {
  player: number;
  // Weapon skill used
  skill: WeaponSkill;
  // Attribute of the used weapon skill
  attr: Attribute;
  // Result so far of the skillchain
  result: Attribute;
}

export class SkillchainStep extends Record<ISkillchainStep>({
  player: undefined,
  skill: undefined,
  attr: undefined,
  result: undefined,
} as any) {}

export type SkillchainSteps = List<SkillchainStep>;

// export class SkillchainSteps extends List<SkillchainStep> {}
