import { Record } from 'immutable';

interface IPoint {
  x: number;
  y: number;
  z?: number;
}

interface IBoundary {
  maxX: number;
  maxY: number;
  maxZ: number;
  minX: number;
  minY: number;
  minZ: number;
}

const defaultBoundary: IBoundary = {
  maxX: 10000,
  maxY: 10000,
  maxZ: 10000,
  minX: -10000,
  minY: -10000,
  minZ: -10000,
};

export class Boundary extends Record<IBoundary>(defaultBoundary) {
  contains(point: IPoint): boolean {
    if (point.z === undefined) {
      return false;
    }
    return (
      this.minX <= point.x &&
      point.x <= this.maxX &&
      this.minY <= point.y &&
      point.y <= this.maxY &&
      this.minZ <= point.z &&
      point.z <= this.maxZ
    );
  }
}

interface IFactors {
  key: string;
  name: string;
  parent: string;
  scaleX: number;
  scaleY: number;
  transX: number;
  transY: number;
  boundaries: Boundary[];
}

const defaultInput: IFactors = {
  key: 'no_map',
  name: 'No Map',
  parent: 'no_map',
  scaleX: 0.5,
  scaleY: 0.5,
  transX: 0,
  transY: 0,
  boundaries: [],
};

interface ISimpleFactors {
  key: string;
  parent: string;
  scaleX: number;
  scaleY: number;
  transX: number;
  transY: number;
  boundaries: IBoundary[];
}

export class Factors extends Record<IFactors>(defaultInput) {
  constructor({ boundaries, ...record }: ISimpleFactors) {
    const newBoundaries = boundaries.map((b) => new Boundary(b));
    super({ ...record, boundaries: newBoundaries });
  }

  contains(point: IPoint): boolean {
    return this.boundaries.some((boundary) => boundary.contains(point));
  }
}
