import { Record } from 'immutable';
import { create } from 'zustand';

interface Settings {
  showBoundaries: boolean;
  showChests: boolean;
  showCoffers: boolean;
  showZonelineId: boolean;
  useVanillaMaps: boolean;
}

const defaultSettingsFactory = Record<Settings>({
  showBoundaries: false,
  showChests: false,
  showCoffers: false,
  showZonelineId: false,
  useVanillaMaps: false,
});

interface SettingsState {
  settings: Record<Settings>;
  setShowBoundaries: (value: boolean) => void;
  setShowChests: (value: boolean) => void;
  setShowCoffers: (value: boolean) => void;
  setShowZonelineId: (value: boolean) => void;
  setUseVanillaMaps: (value: boolean) => void;
}

export const useMapSettings = create<SettingsState>()((set) => ({
  settings: defaultSettingsFactory(),
  setShowBoundaries: (value: boolean) =>
    set((state) => {
      return { settings: state.settings.set('showBoundaries', value) };
    }),
  setShowChests: (value: boolean) =>
    set((state) => {
      return { settings: state.settings.set('showChests', value) };
    }),
  setShowCoffers: (value: boolean) =>
    set((state) => {
      return { settings: state.settings.set('showCoffers', value) };
    }),
  setShowZonelineId: (value: boolean) =>
    set((state) => {
      return { settings: state.settings.set('showZonelineId', value) };
    }),
  setUseVanillaMaps: (value: boolean) =>
    set((state) => {
      return { settings: state.settings.set('useVanillaMaps', value) };
    }),
}));
