const black = '#1d1f21'; // 00
const veryDarkGray = '#282a2e'; // 01
const darkGray = '#373b41'; // 02
const gray = '#969896'; // 03
const lightGray = '#b4b7b4'; // 04
const veryLightGray = '#c5c8c6'; // 05
const almostWhite = '#e0e0e0'; // 06
const white = '#ffffff'; // 07

const red = '#cc6666'; // 08
const orange = '#de935f'; // 09
const yellow = '#f0c674'; // 0A
const green = '#b5bd68'; // 0B
const cyan = '#8abeb7'; // 0C
const blue = '#81a2be'; // 0D
const purple = '#b294bb'; // 0E
const brown = '#a3685a'; // 0F

export const Colors = {
  black,
  veryDarkGray,
  darkGray,
  gray,
  lightGray,
  veryLightGray,
  almostWhite,
  white,

  red,
  orange,
  yellow,
  green,
  cyan,
  blue,
  purple,
  brown,
};
