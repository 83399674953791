import Autocomplete from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { useCallback, useMemo } from 'react';
import { getSpawnsFor } from '@xi/data/maps/spawns';
import { useMapDescriptor } from '@xi/hooks/useMapDescriptor';
import { useNavigate } from '@xi/hooks/useMapsNavigate';
import { useSpawns } from '@xi/hooks/useSpawns';

interface Option {
  id: string;
  label: string;
}

function useHandleClick(): (
  evt: React.SyntheticEvent,
  value: Option[] | null
) => void {
  const navigate = useNavigate();
  return useCallback(
    (evt, value) => navigate({ mob: value?.map((v) => v.id) }),
    [navigate]
  );
}

function useAvailableSpawns(): Option[] {
  const descriptor = useMapDescriptor();
  return useMemo(() => {
    const options: Record<string, Option> = {};
    for (const spawn of getSpawnsFor(descriptor.name)) {
      options[spawn.groupid] = {
        id: spawn.groupid,
        label: `${spawn.mob} (${spawn.groupid})`,
      };
    }
    return Object.values(options).sort((a, b) =>
      a.label < b.label ? -1 : a.label > b.label ? 1 : 0
    );
  }, [descriptor]);
}

export function Spawns(): React.ReactElement<{}, typeof Spawns> {
  const descriptor = useMapDescriptor();
  const handleClick = useHandleClick();

  const options = useAvailableSpawns();
  const spawns = useSpawns();
  const selected = useMemo(() => {
    const result: Record<string, Option> = {};
    for (const spawn of spawns) {
      if (!result[spawn.groupid]) {
        result[spawn.groupid] = {
          id: spawn.groupid,
          label: `${spawn.mob} (${spawn.groupid})`,
        };
      }
    }
    return Object.values(result);
  }, [spawns]);

  return (
    <>
      <FormControl sx={{ pt: 1 }} fullWidth>
        {/* key in the component allows it to be cleared when the map changes */}
        <Autocomplete
          key={descriptor.name}
          disableCloseOnSelect
          multiple
          isOptionEqualToValue={(option, value) => option.id === value?.id}
          value={selected}
          onChange={handleClick}
          options={options}
          renderInput={(params) => <TextField {...params} label="Mob Spawns" />}
        />
        <Typography fontSize={12} sx={{ p: 1 }}>
          Note spawns are from AirSkyBoat and does not include Horizon specific
          mobs/spawns
        </Typography>
      </FormControl>
    </>
  );
}
