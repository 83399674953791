import { Checkbox, FormControlLabel } from '@mui/material';
import humanizeString from 'humanize-string';
import startCase from 'lodash/startCase';
import React from 'react';
import { usePlayers } from '../../stores/usePlayers';
import { Player, PlayerId } from '../../types/skillchain/Player';
import { Weapon } from '../../types/skillchain/Weapon';
import {
  WeaponSkill,
  WeaponToWeaponSkills,
} from '../../types/skillchain/WeaponSkill';
import { WeaponSelect } from './WeaponSelect';

interface PartyMemberProps {
  player: Player;
  setWeapon: (w: Weapon) => void;
  toggleWeaponSkill: (id: PlayerId, weaponSkill: WeaponSkill) => void;
}
export const PartyMember: React.FC<PartyMemberProps> = ({
  setWeapon,
  player,
  toggleWeaponSkill,
}) => {
  const weaponSkills = Object.values(
    WeaponToWeaponSkills[player.weapon]
  ) as WeaponSkill[];
  const removePlayer = usePlayers((s) => s.removePlayer);

  return (
    <div className="party-member">
      <div className="close-icon" onClick={() => removePlayer(player.id)}>
        ✖
      </div>
      <WeaponSelect weapon={player.weapon} setWeapon={setWeapon} />
      <div className="ws-group">
        {weaponSkills.map((ws) => (
          <FormControlLabel
            key={ws}
            className="ws-toggle"
            control={
              <Checkbox
                className="ws-checkbox"
                size="small"
                checked={player.activeWeaponSkills.has(ws)}
                onChange={(e) => {
                  toggleWeaponSkill(player.id, ws);
                }}
              />
            }
            label={startCase(humanizeString(ws))}
          />
        ))}
      </div>
    </div>
  );
};
