import startCase from 'lodash/startCase';
import React from 'react';
import { Attribute } from '../../types/skillchain/Attribute';

interface AttributeIconProps {
  attribute: Attribute;
}

export const AttributeIcon: React.FC<AttributeIconProps> = ({ attribute }) => {
  const filename = startCase(attribute.toLowerCase()) + '-Icon.gif';

  return (
    <img
      className="attribute-icon"
      alt={`Attribute ${filename}`}
      src={process.env.PUBLIC_URL + '/attribute-icon/' + filename}
    />
  );
};
