import { Record, Set } from 'immutable';
import { random, take } from 'lodash';
import { Weapon } from './Weapon';
import { WeaponSkill, WeaponToWeaponSkills } from './WeaponSkill';

let counter = 0;
const getId = () => counter++;

const weapons = Object.keys(WeaponToWeaponSkills);

export type PlayerName = string;
export type PlayerId = number;
export interface PlayerInterface {
  weapon: Weapon;
  name: PlayerName;
  id: PlayerId;
  activeWeaponSkills: Set<WeaponSkill>;
}

export class Player extends Record<PlayerInterface>({
  id: undefined,
  weapon: Weapon.SWORD,
  name: undefined,
  activeWeaponSkills: Set(),
} as any) {
  constructor() {
    const weapon = weapons[random(0, weapons.length - 1)] as Weapon;
    const skills = WeaponToWeaponSkills[weapon]!;
    super({
      id: getId(),
      name: '',
      weapon: weapon,
      activeWeaponSkills: Set(take(skills as any, 3)),
    });
  }
}
