import React from 'react';
import './Skillchain.css';
import { PartyBuilder } from '../components/skillchain/PartyBuilder';
import { SkillchainDisplay } from '../components/skillchain/SkillchainDisplay';

export function SkillchainPage() {
  return (
    <div className="content">
      <div className="party-list">
        <PartyBuilder />
      </div>
      <div className="skillchain-results">
        <SkillchainDisplay />
      </div>
    </div>
  );
}
